<template lang="pug">
  .cookies(
    v-if="isCookieBar"
  )
    .cookies__container
      .cookies__content
        svg.cookies__icon(
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
        )
          path(
            fill="#2D2D2D"
            d="M15.978 0C7.154 0 0 7.154 0 15.978s7.154 15.978 15.978 15.978c.468 0 .928-.03 1.386-.07a4.974 4.974 0 011.128-5.502 5.956 5.956 0 01-.517-2.417 5.992 5.992 0 015.992-5.992c.862 0 1.677.19 2.417.517a4.971 4.971 0 015.502-1.128c.04-.458.07-.918.07-1.386C31.956 7.154 24.802 0 15.978 0zM4.993 15.978a.999.999 0 110-1.997.999.999 0 010 1.997zm4.993 7.989a1.997 1.997 0 110-3.995 1.997 1.997 0 010 3.995zm1.998-13.98a1.997 1.997 0 110-3.995 1.997 1.997 0 010 3.994zm2.995 7.988a.999.999 0 110-1.997.999.999 0 010 1.997zm5.992-13.98a.999.999 0 110 1.997.999.999 0 010-1.998zm.999 9.986a1.997 1.997 0 110-3.995 1.997 1.997 0 010 3.995z"
          )
        .cookies__text
          span.cookies__text-inline {{ $t('cookie_text') }}
          nuxt-link(
            :to="$locatedLink('/privacy-policy/')"
            class="cookies__more"
          ) {{ $t('learn_more') }}
      .cookies__actions
        button.cookies__button(
          data-testid="buttonAcceptCookie"
          @click.prevent="onClickClose"
        ) {{ $t('close') }}
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState('auth', ['currentCountry']),
    ...mapState('window', ['isCookieAlertVisible']),

    isCookieBar () {
      if (this.isCookieAlertVisible && this.currentCountry !== 'US') {
        return true
      }

      return false
    }
  },

  mounted () {
    this.checkCookie()
  },

  methods: {
    ...mapActions('window', ['checkCookie', 'closeCookieAlert']),

    onClickClose () {
      this.closeCookieAlert()
    }
  }
}
</script>

<style lang="scss" scoped>
.cookies {
  position: fixed;
  z-index: 21474836500;
  bottom: 0;
  right: 0;
  left: 0;
  background: $colorWarning;
  font-size: $fontSizeBase;

  @include display-less(smart) {
    font-size: $fontSizeSmall;
  }

  &__container {
    @extend %inner;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: $gap;
    padding-bottom: $gap;

    @include display-less(desktop) {
      display: block;
    }

    @include display-less(smart) {
      display: grid;
      padding-top: $gap / 2;
      padding-bottom: $gap / 2;
      grid-template-columns: 1fr 60px;
    }
  }

  &__icon {
    flex: 0 0 auto;
    display: block;
    margin-right: $gap;

    @include display-less(smart) {
      display: none;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include display-less(desktop) {
      margin: 0 $gap*1.5 0 0;
    }
  }

  &__actions {
    display: flex;

    @include display-less(big) {
      padding-right: $gap*1.5;
    }

    @include display-less(desktop) {
      padding: 0 0 0 $gap*2;
      flex-wrap: wrap;
    }

    @include display-less(smart) {
      margin-left: -$gap;
      padding: 0;
    }
  }

  &__button {
    margin: 0 0 0 $gap;
    line-height: $gap*2;

    @include display-less(desktop) {
      margin-top: $gap;
    }

    @include display-less (smart) {
      margin-top: 0;
    }
  }

  &__button {
    width: 112px;
    padding: 0 $gap;
    border: none;
    border-radius: 4px;
    background: $colorText;
    color: $colorLight;
    font-family: inherit;
    font-size: $fontSizeSmall;
    font-weight: 500;
    text-decoration: none;
    outline: none;
    cursor: pointer;
  }

  &__close {
    position: absolute;
    top: $gap;
    right: $gap;
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    line-height: 1;
    outline: none;
    cursor: pointer;

    &-icon {
      vertical-align: bottom;
    }
  }

  &__text {
    @include display-less (smart) {
      font-size: 12px;
      line-height: 1;
    }
  }

  &__text-inline {
    margin-right: 4px;
  }

  &__more {
    color: $colorText;
    white-space: nowrap;
  }
}
</style>
